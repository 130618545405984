$black-100: rgb(0,0,0);
$black-90:  rgb(26,26,26);
$black-80:  rgb(51,51,51);
$black-70:  rgb(77,77,77);
$black-60:  rgb(102,102,102);
$black-50:  rgb(128,128,128);
$black-40:  rgb(153,153,153);
$black-30:  rgb(179,179,179);
$black-20:  rgb(205,205,205);
$black-15:  rgb(217,217,217);
$black-10:  rgb(229,229,229);
$black-5:   rgb(242,242,242);
$white:     rgb(255,255,255);


$brown: rgb(64, 39, 19);
$green: rgb(0, 104, 56);
$orange: rgb(224, 84, 32);
$brown-card: rgb(136, 91, 65);

$tan-solid: rgb(78, 51, 38);

$tan-darkest: rgb(87, 61, 48);
// $tan-darkest: rgba(78, 51, 38, 0.9); // #contact a:hover
// $tan-darkest: #573d30;

$tan-darker: rgb(131, 112, 103);
// $tan-darker: rgba(78, 51, 38, 0.7);
// $tan-darker: #837067;

$tan-medium: rgb(167, 153, 147);
// $tan-medium: rgba(78, 51, 38, 0.5); // #contact a:link
// $tan-medium: #a79993

$tan-light: rgb(171, 152, 141); // header + footer background

$tan-lighter: rgb(202, 194, 190);
// $tan-lighter: rgba(78, 51, 38, 0.3); // testimonial box
// $tan-lighter: #cac2be;

$tan-lightest: rgb(230, 224, 221);