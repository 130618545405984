@import "_colors";

$font-base: 'Open Sans', Arial, Helvetica, sans-serif;
$font-condensed: 'Open Sans Condensed', Arial, Helvetica, sans-serif;

// prevent the Flash Of Unstlyled Text - FOUT
html.wf-loading * {
  opacity: 0;
}

h1 {
  color: $tan-medium;
  font-family: $font-base;
  font-size: 2.0em;
  font-weight: 300;
  letter-spacing: 0.0em;
  margin: 20px auto 40px auto;
}

h2  {
  color: $white;
  font-family: $font-base;
  font-size: 1.6em;
  font-weight: 300;
  padding: 5px 30px 7px 30px;
  text-align: center;
  text-shadow: 1px 1px 0 $black-20,
               2px 2px 0 $tan-medium;
  text-transform: uppercase;
}

p {
  color: $brown;
  font-family: $font-condensed;
  font-size: 1.4em;
  font-weight: 300;
}

strong {
  color: $brown;
  font-weight: 700;
}

ul  {
  list-style: square inside none;
  padding: 0px 10px 0px 20px;
}

li {
  color: $brown;
  font-family: $font-condensed;
  font-size: 1.4em;
  font-weight: 300;
}

nav a:link {
  font-family: $font-base;
  font-weight: 300;
}

a {
  font-family: $font-condensed;
  font-weight: 300;
}